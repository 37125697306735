.App {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav-link {
  cursor: pointer;
}

.skillicon{
  height: 28px;
}

@media (min-width: 1024px) {
  .sm\:w-128 {
      width: 28rem;
  }
}

@media (max-width: 640px) {
  .sm\:w-128 {
      width: 20rem;
  }
}